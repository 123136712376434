import sticker from "./pic/sticker.png"
import "../style/404.css"

function FourOFour() {
    return (
        <div className="sticker">
        <img src={sticker} alt="sticker of old man" />
        </div>
    )
}

export default FourOFour;